:root {
  --dashboardBg: #EEEEEE;
  --grey: #E8E8E8;
  --sub-grey: #828282;
  --black: #1C1C1C;
  --grey-icon: #C6C6C6;
  --grey-input-bg: #FAFAFA;
  --main: #273C62;
  --blue: #0085FF;
  --error: #FAD5D5;
  --success: #CAF5DD;

  /* Fonts */
  --manrope-font: 'Manrope', sans-serif;
  --primary-font: var(--manrope-font);
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
}

body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dashboardBg);
}

.DateRangePicker:not(.MuiDateRangePickerInput-root)>div:first-child,
.MuiDataGrid-main>div:nth-last-child(2),
.MuiDateRangeCalendar-root>div:first-child {
  display: none;
}

.MuiDataGrid-main *::-webkit-scrollbar {
  height: 10px;
  width: 4px;
}


.MuiDataGrid-main *::-webkit-scrollbar-track {
  background-color: var(--grey-input-bg);
}

.MuiDataGrid-main *::-webkit-scrollbar-thumb {
  background-color: var(--palette-primary-main);
  border-radius: 10px;
}

.validation-tooltip.MuiTooltip-tooltip {
  background-color: var(--toastify-color-error);
}

.validation-tooltip .MuiTooltip-arrow  {
   color: var(--toastify-color-error);
}

[data-mui-color-scheme="dark"] text {
  fill: white !important;
}

.MuiPickersLayout-shortcuts.MuiList-root {
  min-width: 150px;
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

@keyframes slideLeft {
  to {
    transform: translateX(0);
  }
}
